import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import moment from 'moment'
import Swal from 'sweetalert2'
import { Grid } from 'gridjs'
import { RowSelection } from 'gridjs-selection'
import { esES } from "gridjs/l10n/dist/l10n";

export default class extends Controller {
  static targets = [
    'client', 'items', 'availableIncomes', 'addBtn', 'totalLabel',
    'amount', 'container', 'exchangeRate', 'currency', 'total',
    'initialIncomes', 'exchangeRateInput'
  ]

  connect(){
    this.incomes = this.hasInitialIncomesTarget ? JSON.parse(this.initialIncomesTarget.value) : []
    this.decorateIncomes(this.incomes)
    esES.search.placeholder = 'Buscar Folio...'

    this.table = new Grid({
      language: esES,
      search: true,
      height: '320px',
      columns: [
        { id: 'id', name: 'id', hidden: true },
        { id: 'stamped_at', name: 'FECHA' },
        { id: 'folio', name: 'FOLIO' },
        { id: 'total_with_currency', name: 'TOTAL' },
        { id: 'payment_balance_with_currency', name: 'PENDIENTE' },
        { 
          id: 'checkbox',
          name: '',
          plugin: {
            component: RowSelection, 
            props: {
              id: (row) => row.cell(0).data
            }
          }
        },
      ],
      className: {
        thead: 'sticky top-0'
      },
      data: this.incomes 
    }).render(this.containerTarget)

    this.selectedIncomeIds = []
    this.addedIncomeIds = []

    this.table.on('ready', () => {
      console.log('ready')
      const checkboxPlugin = this.table.config.plugin.get('checkbox');
      if(checkboxPlugin.props.store !== undefined){
        checkboxPlugin.props.store.on('updated', (state, prevState) => {

          this.selectedIncomeIds = state.rowIds
        });
      }
    })

    this.calculateTotal()
  }

  preventKeydownEnter(event) { 
    if(event.key === 'Enter'){
      event.preventDefault(); 
    }; 
  }

  itemRemoved(e){
    let deleted = e.detail[0]
    let id = parseInt(deleted.dataset.id)

    let index = this.addedIncomeIds.indexOf(id)
    this.addedIncomeIds.splice(index, 1)
    console.log(index, this.addedIncomeIds, id)

    let amount = deleted.closest('tr').querySelector('.amount')
    amount.dataset.deleted = true
    this.calculateTotal()
  }

  addIncomes(){

    if(this.selectedIncomeIds.length == 0){
      Swal.fire('Seleccione al menos una factura para agregar')
      return
    }

    let selected = this.getSelectedIncomes()
    console.log(selected)
    for(let object of selected){

      document.cocoon.insert(this.addBtnTarget, (node, n2) => {
        this.buildTemplate(node, object)
      })
      this.addedIncomeIds.push(object.id)

    }

    this.calculateTotal()
  }

  getSelectedIncomes(){
    return this.incomes.filter( (object) => {
      let selected = this.selectedIncomeIds.indexOf(object.id) > -1
      let alreadyAdded = this.addedIncomeIds.indexOf(object.id) > -1
      console.log(object.id, selected, alreadyAdded)
      return selected && !alreadyAdded
    } )
  }

  loadIncomes(){
    let id = this.clientTarget.value
    this.fetchIncomes(id)
  }

  decorateIncomes(incomes){
    for(const income of incomes){
      let label = `${income.serie}${income.folio}`
      income.folio = label
      income.stamped_at = moment(income.stamped_at).format('L')
      income.total_with_currency = `$${income.total} ${income.currency}`
      income.payment_balance_with_currency = `$${income.payment_balance} ${income.currency}`
    }
  }

  paintIncomes(incomes) {
    this.decorateIncomes(incomes)
    
    this.table.updateConfig({
      data: incomes
    }).forceRender()
    this.incomes = incomes
  }

  fetchIncomes(id, callback) {
    if(id === undefined || id === ''){
      // this.availableIncomesTarget.innerHTML += this.getDefaultRow('Seleccione un cliente')
      return;
    }

    Rails.ajax({
      url: '/api/incomes',
      type: 'GET',
      data: `status=1&client_id=${id}`,
      success: this.paintIncomes.bind(this) ,
      error: function(error){
        console.log(error)
      }
    })

  }

  buildTemplate(row, object){
    console.log(object)
    row.dataset.id = object.id
    row.querySelector('.stamped_at').innerHTML = moment(object.stamped_at).format('L')
    row.querySelector('.folio').innerHTML = object.folio
    row.querySelector('.payment_balance').innerHTML = object.payment_balance_with_currency
    row.querySelector('.income').value = object.id

    let currency = this.currencyTarget.value.toUpperCase()
    let exchangeRate = this.exchangeRateInputTarget.value
    let inputCurrency = object.currency

    let val = parseFloat(object.payment_balance)
    if(currency === 'MXN' && inputCurrency === 'USD') {
      val = ((val * 100) * (exchangeRate)) / 100
    } else if(currency === 'USD' && inputCurrency === 'MXN') {
      val = ((val * 100) / (exchangeRate)) / 100
    }
    if(isNaN(val)) val = 0
    val = Math.floor(val * 1000) / 1000

    row.querySelector('.amount').value = val
  }

  calculateTotal(){
    let total = 0
    for(const amountInput of this.amountTargets){
      if(amountInput.dataset.deleted) continue;

      let val = parseFloat(amountInput.value)
      total += val
    }

    this.totalLabelTarget.innerHTML = `${total.toFixed(2)} ${this.currencyTarget.value.toUpperCase()}` 
    this.totalTarget.value = total.toFixed(2)
  }

  updateCurrency(e){
    let value = e.currentTarget.value
    this.calculateTotal()
  }
}

